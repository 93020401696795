<template>
  <v-container>
    <BaseTitlePage class="my-0" />

    <BaseSearchPage
      title="Selecione o cliente e o contrato para continuar"
      class="mt-0"
    >
      <template v-slot:form>
        <v-form
          @submit.prevent="search"
          ref="form"
          lazy-validation
          v-model="valid"
        >
          <v-row dense>
            <v-col cols="12" xl="5" lg="5" md="5" sm="6">
              <BaseCustomersAutocomplete
                label="Cliente"
                hide-details
                required
                :rules="[required]"
                :filter="model.customerName"
                v-model="model.customerId"
                @change="clearPage"
              />
            </v-col>
            <v-col
              cols="12"
              xl="5"
              lg="5"
              md="5"
              sm="6"
              v-if="model.customerId"
            >
              <BaseContractsMovements
                label="Contrato"
                hide-details
                includeInactive
                required
                :rules="[requiredLength]"
                :multiple="false"
                :customerId="model.customerId"
                v-model="model.contractId"
                @change="handleAvailableDates"
              />
            </v-col>
            <v-col
              cols="12"
              xl="2"
              lg="2"
              md="2"
              sm="2"
              v-if="model.contractId && !tabVisible"
            >
              <BaseDatePickerMonth
                label="Mês de fatura"
                hide-details
                required
                :rules="[required]"
                :allowedMonths="allowedMonths"
                v-model="model.referenceDate"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="2">
              <BaseButton
                type="submit"
                height="40"
                color="secondary"
                title="Continuar"
                :disabled="!valid"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </BaseSearchPage>

    <BaseSearchPage class="mt-0" v-if="tabVisible">
      <template v-slot:form>
        <v-row dense class="align-center">
          <v-col cols="12" xl="2" lg="2" md="2" sm="2">
            <BaseDatePickerMonth
              hide-details
              label="Mês de fatura"
              :allowedMonths="allowedMonths"
              v-model="model.referenceDate"
              @change="search"
            />
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="3" sm="3" class="b-column-invoice">
            <div>
              Status
              <span :class="situation">{{ invoice.situation }}</span>
            </div>
          </v-col>
          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="4"
            class="b-column-invoice"
            v-if="invoice.updatedAt"
          >
            <div>
              Ultima atualização

              <span>
                {{ formatDateTime(invoice.updatedAt) }}

                <v-tooltip bottom v-if="invoice.situation === 'Fatura aberta'">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      icon
                      class="btn-rotate"
                      v-on="on"
                      @click="updateInvoice"
                    >
                      <v-icon small>fa-solid fa-rotate</v-icon>
                    </v-btn>
                  </template>
                  <span>Atualizar fatura</span>
                </v-tooltip>
              </span>
            </div>
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="3" sm="3" class="b-column-invoice">
            <div>
              Vencimento
              <span>
                {{ formatDate(invoice.dueDate) }}

                <v-tooltip
                  bottom
                  v-if="
                    invoice.isFirstInvoice &&
                    invoice.situation === 'Fatura aberta'
                  "
                >
                  <template v-slot:activator="{ on }">
                    <v-btn small icon v-on="on" @click="dialog = !dialog">
                      <v-icon size="15">fa-solid fa-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Alterar vencimento da primeira fatura</span>
                </v-tooltip>
              </span>
            </div>
          </v-col>
        </v-row>
      </template>
    </BaseSearchPage>

    <v-row class="mt-0" v-if="tabVisible">
      <v-col cols="12">
        <v-tabs
          v-model="tab"
          show-arrows
          class="b-tab-horizontal b-tab-invoice"
        >
          <template v-for="(header, index) in headers">
            <v-tab v-if="header.visible" :href="`#${header.href}`" :key="index">
              {{ header.title }}
            </v-tab>
          </template>

          <v-tabs-items v-model="tab" class="b_tabs__items pt-4">
            <template v-for="(content, index) in headers">
              <v-tab-item
                v-if="content.visible"
                :value="content.href"
                :key="index"
              >
                <component
                  v-if="content.href === tab"
                  :is="content.component"
                  :customerId="model.customerId"
                  :contractId="model.contractId"
                  :referenceDate="model.referenceDate"
                  @reloadDates="reloadDates"
                  @getInvoice="getInvoice"
                />
              </v-tab-item>
            </template>
          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>

    <DialogUpdateInvoiceDate
      v-if="dialog"
      :dialog="dialog"
      :invoiceId="invoice.id"
      :dueDate="invoice.dueDate"
      @closeModal="closeModal"
    />
  </v-container>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { mapGetters } from 'vuex';
import { InvoiceService } from '@/services/api/invoice';

export default {
  mixins: [rulesMixin],

  components: {
    PageDocuments: () => import('@/components/pages/invoice/page-documents'),
    PageInvoice: () => import('@/components/pages/invoice/page-invoice'),
    PageMovements: () => import('@/components/pages/invoice/page-movements'),
    PageUnifiedInvoice: () =>
      import('@/components/pages/invoice/page-unified-invoice'),
    PageParticularities: () =>
      import('@/components/pages/invoice/page-particularities'),
    DialogUpdateInvoiceDate: () =>
      import('@/components/pages/invoice/dialog/dialog-update-invoice-date')
  },

  data: () => ({
    dialog: false,
    tab: null,
    valid: true,
    model: {
      customerName: null,
      customerId: null,
      contractId: null,
      referenceDate: null
    },
    availableDates: []
  }),

  computed: {
    ...mapGetters({
      headers: 'invoice/getTabs',
      invoice: 'invoice/getInvoice'
    }),

    tabVisible() {
      return this.invoice && this.invoice.id ? true : false;
    },

    situation() {
      return this.invoice && this.invoice.situation === 'Fatura aberta'
        ? 'success--text'
        : 'error--text';
    }
  },

  created() {
    this.$store.commit('invoice/setInvoice', null);

    this.createFilters();
  },

  methods: {
    createFilters() {
      const { customerName, customerId, contractId } = this.$route.query;

      if (customerName && customerId && contractId) {
        this.model = {
          customerName: customerName,
          customerId: customerId,
          contractId: contractId,
          referenceDate: null
        };

        this.reloadDates();
      }
    },

    search() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      this.getInvoice(this.model);
      this.tab = 'tab-1';
    },

    async getInvoice(params) {
      try {
        const invoiceService = new InvoiceService();
        const { status, data } = await invoiceService.getInvoice(params);

        if (status === 200) {
          this.$store.commit('invoice/setInvoice', data);
        }
      } catch (error) {
        this.$store.commit('invoice/setInvoice', null);
        throw new Error(error);
      }
    },

    async updateInvoice() {
      try {
        const invoiceService = new InvoiceService();
        const { status, data } = await invoiceService.getUpdatedInvoice({
          invoiceId: this.invoice.id
        });

        if (status === 200) {
          this.$store.commit('invoice/setInvoice', data);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async handleAvailableDates() {
      this.model.referenceDate = null;
      this.$store.commit('invoice/setInvoice', null);
      this.$refs.form.resetValidation();

      this.reloadDates();
    },

    async reloadDates() {
      try {
        const invoiceService = new InvoiceService();
        const { status, data } = await invoiceService.getAvailableDates(
          this.model
        );

        if (status === 200) {
          this.availableDates = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    allowedMonths(val) {
      return this.availableDates.includes(val);
    },

    clearPage() {
      this.model.contractId = null;
      this.model.referenceDate = null;
      this.$store.commit('invoice/setInvoice', null);
      this.$refs.form.resetValidation();
    },

    closeModal(search) {
      this.dialog = false;

      if (search) {
        this.search();
      }
    },

    formatDate(val) {
      if (val) {
        const date = new Date(val);

        return date.toLocaleDateString('pt-BR', {
          month: 'long',
          day: 'numeric',
          year: 'numeric'
        });
      }

      return null;
    },

    formatDateTime(value) {
      if (value) {
        const date = new Date(value);

        return date.toLocaleDateString('pt-BR', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric'
        });
      }

      return null;
    }
  }
};
</script>

<style lang="scss">
.biobe {
  .b-tab-invoice {
    &.theme--light {
      box-shadow: 0px 0px 2px 1px $shadow-light;
    }

    &.theme--dark {
      box-shadow: 0px 0px 2px 1px $shadow-dark;
    }
  }
}
</style>
