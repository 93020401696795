import { objectWithoutProperties } from '@/helpers/configureRequest';
import { HttpClient } from '@/services/http';

export class InvoiceService extends HttpClient {
  async getInvoice(params) {
    const newParams = objectWithoutProperties(params, [
      'customerId',
      'contractId'
    ]);

    const result = await this.get(
      `v1/invoice/customer/${params.customerId}/contract/${params.contractId}`,
      newParams
    );

    return result;
  }

  async getInvoiceCalculation(params) {
    const newParams = objectWithoutProperties(params, ['invoiceId']);

    const result = await this.get(
      `v1/invoice/${params.invoiceId}/calculation`,
      newParams
    );

    return result;
  }

  async getDocuments(params) {
    const newParams = objectWithoutProperties(params, ['invoiceId']);

    const result = await this.get(
      `v1/invoice/${params.invoiceId}/documents`,
      newParams
    );

    return result;
  }

  async uploadInvoiceDocument(params) {
    let form = new FormData();
    const { file } = params;
    form.append('file', file);

    const result = await this.post(
      `v1/invoice/${params.invoiceId}/documents/upload`,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    return result;
  }

  async downloadInvoiceDocument({ invoiceId, documentId }) {
    const result = await this.get(
      `v1/invoice/${invoiceId}/documents/${documentId}/download`
    );

    return result;
  }

  async removeInvoiceDocument({ invoiceId, documentId }) {
    const result = await this.delete(
      `v1/invoice/${invoiceId}/documents/${documentId}`
    );

    return result;
  }

  async getMovements(params) {
    const newParams = objectWithoutProperties(params, ['invoiceId']);

    const result = await this.get(
      `v1/invoice/${params.invoiceId}/movements`,
      newParams
    );

    return result;
  }

  async downloadBaseInvoice({ invoiceId }) {
    const result = await this.get(`v1/invoice/${invoiceId}/base/download`);
    return result;
  }

  async downloadInvoiceMovements({ invoiceId }) {
    const result = await this.get(`v1/invoice/${invoiceId}/movements/download`);
    return result;
  }

  async getUnifiedInvoices({ invoiceId }) {
    const result = await this.get(`v1/invoice/${invoiceId}/unified`);
    return result;
  }

  async downloadUnifiedInvoice({ invoiceId }) {
    const result = await this.get(`v1/invoice/${invoiceId}/unified/download`);
    return result;
  }

  async closingInvoice(params) {
    const newParams = objectWithoutProperties(params, [
      'invoiceId',
      'customerId',
      'contractId',
      'referenceDate'
    ]);

    const result = await this.put(
      `v1/invoice/${params.invoiceId}/closing`,
      newParams
    );

    return result;
  }

  async reopenInvoice(params) {
    const result = await this.put(`v1/invoice/${params.invoiceId}/reopen`);
    return result;
  }

  async getUpdatedInvoice({ invoiceId }) {
    const result = await this.get(`v1/invoice/${invoiceId}/update`);
    return result;
  }

  async getAvailableDates({ customerId, contractId }) {
    const result = await this.get(
      `v1/invoice/customer/${customerId}/contract/${contractId}/available-dates`
    );

    return result;
  }

  async postInvoiceCode(params) {
    const result = await this.post(`v1/invoice/code`, params);
    return result;
  }

  async putInvoiceDueDate(params) {
    const result = await this.put('v1/invoice/due-date', params);
    return result;
  }

  async getAnnotation(invoiceId) {
    const result = await this.get(`v1/invoice/${invoiceId}/annotation`);
    return result;
  }

  async saveAnnotation(params) {
    const result = await this.put('v1/invoice/annotation', params);
    return result;
  }
}
